.container {
  display: table;
}

.container>.row {
  display: table-row;
}

.container>.row>* {
  display: table-cell;
  white-space: nowrap;
}

.col {
  width: 100%;
}